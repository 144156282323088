@import "vars.scss";
.nav-menu {
  position: fixed;
  left: -100vh;
  top: 0;
  width: 70vw;
  height: 100vh;
  background-color: #dcdcdc;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 999;
}

.nav-menu:global(.active) {
  left: 0;
  opacity: 1;
}

.mobile-nav-menu-header,
.mobile-sc-icon-header {
  display: none;
}

.mobile-nav-menu-header:global(.active),
.mobile-sc-icon-header:global(.active) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 1rem;
}

.mobile-sc-icon-header:global(.active) {
  justify-content: center;
}

.menu-icon {
  display: block;
}

.menu-icon i {
  color: rgb(17, 17, 17);
  font-size: 2rem;
  display: block;
  margin-top: 0rem;
  transition: all ease 0.4s;
}

.menu-icon i:global(.open) {
  color: $secondary-color;
  margin-top: 0.6rem;
}

@media (min-width: 768px) {
  .mobile-sc-icon-header {
    display: none !important;
  }

  .nav-menu {
    position: relative;
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    opacity: 1;
    transition: all 0.5s ease;
    top: initial;
    left: initial;
    background-color: initial;
    align-items: center;
  }

  .menu-icon {
    display: none;
  }
}
