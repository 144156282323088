@import "vars.scss";
@import "preflight.css";
@tailwind components;
@tailwind utilities;

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

section:not(.no-padding) {
  padding-top: 4rem /* 64px */;
  padding-bottom: 4rem /* 64px */;
}

@media (min-width: 1024px) {
  section:not(.no-padding) {
    padding-top: 4rem /* 64px */;
    padding-bottom: 4rem /* 64px */;
  }
}
@media (min-width: 1280px) {
  section:not(.no-padding) {
    padding-top: 5rem /* 80px */;
    padding-bottom: 5rem /* 80px */;
  }
}
@media (min-width: 1536px) {
  section:not(.no-padding) {
    padding-top: 6rem /* 96px */;
    padding-bottom: 6rem /* 96px */;
  }
}

address {
  font-style: normal;
}

// ---

p,
ul {
  font-size: 16px;
  @apply text-black;
  font-family: "Open Sans", sans-serif;
}
html {
  overflow-x: hidden;
}

html,
.openSans {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
.Saira {
  font-family: "Saira", sans-serif;
}

::selection {
  background: #f5c682;
  // background: #dc2626;
  color: #000;
}

#nav-item-contact > a::after {
  display: none;
}

// to fix the footer always on the bottom of the page
main {
  min-height: calc(100vh - 112px - 64px) !important;
}
