@import "vars.scss";
.root {
  color: #fff;
  font-size: 0.8rem;
  background-color: $secondary-color;
  height: 32px;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover:not(:global(.loading)):not(:global(.disabled)),
  &:active:not(:global(.loading)):not(:global(.disabled)) {
    background-color: #c90505;
  }
  &:global(.loading),
  &:global(.disabled) {
    cursor: initial;
    color: rgba(#fff, 0.4);
    background-color: rgba($secondary-color, 0.5);
  }
  &:focus {
    outline: none;
  }
  &:global(.outlined) {
    color: $secondary-color;
    background-color: transparent;
    border: 1px solid $secondary-color;
    &:global(.white) {
      color: white;
      background-color: transparent;
      border-color: white;
    }
  }
  &:global(.black) {
    background-color: $base-color;
    color: white;
  }
  &:hover:not(:global(.loading)):not(:global(.disabled)):global(.outlined),
  &:active:not(:global(.loading)):not(:global(.disabled)):global(.outlined) {
    background-color: $secondary-color;
    color: #fff;
  }

  .loader,
  .loader:before,
  .loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1.2em;
    height: 2em;
  }
  .loader {
    color: #ffffff;
    text-indent: -9999em;
    margin: 0 auto;
    position: relative;
    font-size: 3px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: "";
  }
  .loader:before {
    left: -1.6em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 1.6em;
  }
  &.full-width {
    width: auto !important;
  }
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
}
