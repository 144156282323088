@import "vars.scss";
.link {
  margin: 0.6rem 2rem;
  width: auto;
  display: block;

  a {
    display: inline;
    font-size: 14px !important;
    // line-height: 1;
    margin: 0px;
    padding: 2px;
    position: relative;

    &:hover {
      color: $secondary-color;
    }
    &:global(.active) {
      font-weight: bold;
      color: $secondary-color;
    }
  }
}
.arrow-down {
  border: solid $base-color;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 1rem;
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transition: all ease 0.4s;
}

@media (min-width: 768px) {
  .link {
    a {
      font-size: 0.8rem;

      &:after {
        content: "/";
        padding-left: 20px;
        color: black;
      }
    }
    transition: all ease 0.4s;
    position: relative;
    margin: 0.6rem 0.6rem;
    padding: 1rem 0rem;

    .dropdown-content {
      display: none;
      position: absolute;
      width: 180px;
      background-color: rgb(58 58 58 / 10%);
      left: 0;
      z-index: 100;
      font-size: smaller;
      font-weight: 400;
      gap: 0.2rem;
      margin-top: 0.8rem;
      padding-bottom: 0.6rem;
      backdrop-filter: blur(1rem);
      a {
        padding: 0.4rem 0.6rem;
      }
      a:after {
        content: "";
      }
    }

    &:hover .dropdown-content {
      display: flex;
      flex-direction: column;
    }

    a:hover .arrow-down {
      border-color: $accent-color;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      margin-bottom: 0px;
    }
  }
}
