@import "vars.scss";
.root {
  transition: background 200ms ease;
  & > div > div {
    height: 4rem;
  }
}

@media (min-width: 768px) {
  .root {
    & > div > div {
      transition: height 200ms ease;
      height: 7rem;
    }
    &:global(.scrolled) {
      & > div > div {
        height: 5rem;
      }
    }
  }
}
